import { Box, Typography } from '@mui/material';

export function ByCreator({
  username,
  isSmall = false,
}: {
  username: string;
  isSmall?: boolean;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        fontWeight: 300,
        gap: 1,
        pt: 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: '14px', md: isSmall ? '14px' : '16px' },
          color: '#9B9FA3',
        }}
      >
        By
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '14px', md: isSmall ? '14px' : '16px' },
        }}
      >
        {username}
      </Typography>
    </Box>
  );
}
